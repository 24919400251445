<template>
  <header>
    <div v-if="$store.state.empresa && $store.state.user.cookieBanner && $store.state.user.stripe.plano === 'basic'" class="bg-green-500 text-white font-bold text-center p-2">
      <a href="/stripe">
        Você está aproveitando os beneficios do Cookie da Fácil no plano grátis, assine agora e conheça o nosso plano Pro!
      </a>
    </div>
    <div class="grid grid-cols-12">
      <div class="col-span-12 md:col-span-2">
        <div class="flex items-center justify-center w-full h-full relative py-2 md:py-0">
          <img v-if="$store.state.empresa && $store.state.empresa.logo && $store.state.empresa.logo.filename" class="h-12 2xl:h-16 object-cover" :src="`${url_api}/upload?mimetype=image/jpeg&filename=${$store.state.empresa.logo.filename}&folder=empresas`" />
          <a v-if="$store.state.empresa && !$store.state.empresa.logo.filename && !$store.state.user.cookieBanner" href="#" class="text-base font-semibold tracking-widest text-gray-900  rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline">{{ $store.state.empresa.nome }}</a>
          
          <!-- Cookie BANNER ONLY -->
          <a v-if="$store.state.empresa && $store.state.user.cookieBanner" href="#" class="flex align-middle justify-center items-center space-x-2 gap-2 text-base font-semibold tracking-widest text-gray-900  rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline">
            Cookies da Fácil

            <a href="/stripe">
              <svg v-if="$store.state.user.stripe.plano === 'premium'" class="bg-yellow-500 text-white p-1 rounded-lg" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M248,80a28,28,0,1,0-51.12,15.77l-26.79,33L146,73.4a28,28,0,1,0-36.06,0L85.91,128.74l-26.79-33a28,28,0,1,0-26.6,12L47,194.63A16,16,0,0,0,62.78,208H193.22A16,16,0,0,0,209,194.63l14.47-86.85A28,28,0,0,0,248,80ZM128,40a12,12,0,1,1-12,12A12,12,0,0,1,128,40ZM24,80A12,12,0,1,1,36,92,12,12,0,0,1,24,80ZM220,92a12,12,0,1,1,12-12A12,12,0,0,1,220,92Z"></path></svg>
              <svg v-if="$store.state.user.stripe.plano === 'basic'" class="bg-green-500 text-white p-1 rounded-lg" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M216,72H180.92c.39-.33.79-.65,1.17-1A29.53,29.53,0,0,0,192,49.57,32.62,32.62,0,0,0,158.44,16,29.53,29.53,0,0,0,137,25.91a54.94,54.94,0,0,0-9,14.48,54.94,54.94,0,0,0-9-14.48A29.53,29.53,0,0,0,97.56,16,32.62,32.62,0,0,0,64,49.57,29.53,29.53,0,0,0,73.91,71c.38.33.78.65,1.17,1H40A16,16,0,0,0,24,88v32a16,16,0,0,0,16,16v64a16,16,0,0,0,16,16h60a4,4,0,0,0,4-4V120H40V88h80v32h16V88h80v32H136v92a4,4,0,0,0,4,4h60a16,16,0,0,0,16-16V136a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72ZM84.51,59a13.69,13.69,0,0,1-4.5-10A16.62,16.62,0,0,1,96.59,32h.49a13.69,13.69,0,0,1,10,4.5c8.39,9.48,11.35,25.2,12.39,34.92C109.71,70.39,94,67.43,84.51,59Zm87,0c-9.49,8.4-25.24,11.36-35,12.4C137.7,60.89,141,45.5,149,36.51a13.69,13.69,0,0,1,10-4.5h.49A16.62,16.62,0,0,1,176,49.08,13.69,13.69,0,0,1,171.49,59Z"></path></svg>
            </a>
          </a>

          <a v-if="!$store.state.empresa"  href="#" class="text-base font-semibold tracking-widest text-gray-900  rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline">Fácil LGPD</a>
          <button class="absolute right-3 md:hidden rounded-lg focus:outline-none focus:shadow-outline" @click="$store.commit('setOpenMobileNav')">
            <svg fill="currentColor" viewBox="0 0 20 20" class="w-7 h-7">
              <path v-show="!$store.state.openMobileNav" fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clip-rule="evenodd"></path>
              <path v-show="$store.state.openMobileNav" fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
            </svg>
          </button>
        </div>
      </div>
      <div class="col-span-3 flex items-center px-2 sm:px-6 lg:px-8" :style="{ 'background-color': $store.state.colorSecundaria }">
        <router-link to="/notificacoes" class="flex relative">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-7 md:w-7 text-white" :style="{ 'color': invertColor($store.state.colorSecundaria, true)}" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
          </svg>
          <span
            v-if="$store.state.notificacoes > 0"
            class="absolute -top-1 -right-4 flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-500 rounded-full"
            >{{ $store.state.notificacoes }}</span
          >
        </router-link>
      </div>
      <div class="col-span-9 md:col-span-7 px-2 sm:px-6 lg:px-8" :style="{ 'background-color': $store.state.colorSecundaria }">
        <div class="relative flex items-center justify-end h-16">
          <div class="mr-4 hidden md:inline">
            <router-link to="/assinatura" v-if="$store.state.user && $store.state.user.freeTrial" class="text-white font-r font-medium text-base hover:text-yellow-300" :style="{ 'color': invertColor($store.state.colorSecundaria, true)}">
              Dias de Teste Gratis: {{ $moment($store.state.user.data).add(15,'days').endOf('days').diff($moment().endOf('days'), 'days') > 0 ? $moment($store.state.user.data).add(15,'days').endOf('days').diff($moment().endOf('days'), 'days') : 'Finalizando' }}
            </router-link>
          </div>
          <div v-if="$store.state.user.consultor" class="float-right hidden md:flex items-center h-full">
            <img v-if="$store.state.user && $store.state.user.logo && $store.state.user.logo.filename" class="h-12 object-cover" :src="`${url_api}/upload?mimetype=image/jpeg&filename=${$store.state.user.logo.filename}&folder=empresas`" />
          </div>
          <router-link class="inline-flex px-3 py-2 rounded-md" :style="{ 'color': invertColor($store.state.colorSecundaria, true)}" :to="$store.state.user.cliente ? `/cadastros/pessoas/form/${$store.state.user._id}` : $store.state.user.profissional ? `/cadastros/profissionais/form/${$store.state.user._id}` : `/users/form/${$store.state.user._id}`">
              <img 
                v-if="$store.state.user && $store.state.user.foto && $store.state.user.foto.filename" 
                alt="icon-avatar"
                class="w-10 h-10 rounded-full object-cover"
                :src="`${url_api}/upload?mimetype=image/jpeg&filename=${$store.state.user.foto.filename}&folder=empresas`"
              />
              <img
                v-else
                src="@/assets/avatar.png"
                alt="icon-avatar"
                class="w-10 h-10 rounded-full object-cover"
              />
              <div class="block text-white hover:bg-gray-700 hover:text-yellow-400 text-sm_2 font-medium ml-3" :style="{ 'color': invertColor($store.state.colorSecundaria, true)}">
                <b>{{$store.state.user ? $store.state.user.name : ''}}</b>
                <br/>
                <small>Seu Perfil</small>
              </div>
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {};
  },
  methods: {},
};
</script>