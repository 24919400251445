import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isLogged: false,
    token: false,
    empresa: null,
    user: {},
    colorPrincipal: '#0097B2',
    colorSecundaria: '#374151',
    menuTotal: true,
    openMobileNav: false,
    notificacoes: 0,
  },
  mutations: {
    setNotificacoes (state, notificacoes) {
      state.notificacoes = notificacoes;
    },
    setColor(state, colors) {
      state.colorPrincipal = colors.colorPrincipal;
      state.colorSecundaria = colors.colorSecundaria;
    },
    setOpenMobileNav (state) {
      state.openMobileNav = !state.openMobileNav;
    },
    setMenuTotal (state) {
      state.menuTotal = !state.menuTotal;
    },
    setEmpresa(state, empresa) {
      state.empresa = empresa;
    },
    removeEmpresa(state) {
      state.empresa = null;
    },
    setPago(state, pago) {
      state.user.pago = pago;
    },
    setFreeTrial(state, status) {
      state.user.freeTrial = status
    },
    login(state, user) {
      state.isLogged = true;
      state.token = localStorage.getItem('token');
      state.user = {...user};
    },
    setProName(state, name) {
      state.user.name = name
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    setStripe(state, stripe) {
      state.user.stripe = stripe
    },
    async logout(state) {
      state.isLogged = false;
      state.token = false;
      state.user = {};
      state.empresa = null;
      state.notificacoes = 0;
      state.colorPrincipal = '#0097B2';
      state.colorSecundaria = '#374151';
      localStorage.removeItem('token');
      localStorage.removeItem('empresa');
    },
  },
});

export default store;
