<template>
  <div id="app" class="bg-white grid grid-cols-12">

    <Header v-if="$store.state.isLogged" class="col-span-12" />
    <Menu 
      v-if="$store.state.isLogged"
      class="col-span-12"
      :class="{'md:col-span-2 xl:col-span-2': $store.state.menuTotal, 'md:col-span-1 xl:col-span-1': !$store.state.menuTotal }"  
    />

    <div :class="{'col-span-12 md:col-span-10 xl:col-span-10': $store.state.isLogged, 'col-span-12': !$store.state.isLogged, 'col-span-9 md:col-span-11 xl:col-span-11': $store.state.isLogged && !$store.state.menuTotal }">
      <vue-confirm-dialog></vue-confirm-dialog>
      <router-view />
    </div>

    <modal name="popUp" :adaptive="true" :height="'auto'">
       <div v-if="notificacao" class="px-6 py-8 bg-modal bg-cover bg-no-repeat bg-center">
          <h2 class="text-2.5xl font-semibold text-white text-center">{{ notificacao.titulo }}</h2>
          <p class="text-base text-white my-6">{{ notificacao.descricao }}</p>
          <button @click="okNotificacao" type="button" class="inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-base font-medium rounded-sm text-gray-800 bg-white hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400">
            OK
          </button>
       </div>
    </modal>

    <modal name="pesquisaComoSoube" :adaptive="true" :height="'auto'">
       <div class="px-6 md:px-10 py-5 bg-modal bg-cover bg-no-repeat bg-center">
          <h2 class="text-2.5xl mb-2 font-semibold text-white text-center">Como você soube da Fácil ?</h2>
          <div class="grid grid-cols-12 gap-4 mt-2">
            <div class="col-span-12">
              <label for="colega" class="block items-center text-xl text-white my-4">
                <input type="radio" class="h-4 w-4 border-none" id="colega" name="comosoube" v-model="form.resposta" value="Um colega me indicou">
                <span class="ml-5">Um colega me indicou</span>
              </label>
              <label for="instagram" class="block items-center text-xl text-white my-4">
                <input type="radio" class="h-4 w-4 border-none" id="instagram" name="comosoube" v-model="form.resposta" value="Achei no Instagram">
                <span class="ml-5">Achei no Instagram</span>
              </label>
              <label for="linkedIn" class="block items-center text-xl text-white my-4">
                <input type="radio" class="h-4 w-4 border-none" id="linkedIn" name="comosoube" v-model="form.resposta" value="Achei no LinkedIn">
                <span class="ml-5">Achei no LinkedIn</span>
              </label>
              <label for="google" class="block items-center text-xl text-white my-4">
                <input type="radio" class="h-4 w-4 border-none" id="google" name="comosoube" v-model="form.resposta" value="Procurando no Google">
                <span class="ml-5">Procurando no Google</span>
              </label>
              <label for="whatsapp" class="block items-center text-xl text-white my-4">
                <input type="radio" class="h-4 w-4 border-none" id="whatsapp" name="comosoube" v-model="form.resposta" value="Alguém me informou pelo Whatsapp">
                <span class="ml-5">Alguém me informou pelo Whatsapp</span>
              </label>
              <label for="outro" class="block items-center text-xl text-white my-4">
                <input type="radio" class="h-4 w-4 border-none" id="outro" name="comosoube" v-model="form.resposta" value="Outra forma">
                <span class="ml-5">Outra forma</span>
              </label>
              <button @click="save" type="button" class="inline-flex justify-center py-2 px-8 mt-5 border border-transparent shadow-sm text-base font-medium rounded-sm text-gray-800 bg-white hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400">
                  Salvar
              </button>
            </div>
          </div>
       </div>
    </modal>
    
    <modal name="gostariaDeAssinar" :adaptive="true" :height="'auto'">
      <div class="container mx-auto p-6 max-h-screen overflow-y-auto">
          <h1 class="text-3xl font-bold mb-4">Gestão Completa da LGPD</h1>
          <p class="mb-4">Caro Assinante,</p>
          <p class="mb-4">Você já deu o primeiro passo importante para a conformidade com a LGPD ao utilizar nosso <b>módulo básico de cookies</b>. 
            Parabéns! Mas, você sabia que pode garantir uma gestão completa e eficiente de todos os aspectos da LGPD com nossos módulos avançados?</p>
          <h2 class="text-2xl font-semibold mb-4">Por que investir?</h2>
          <ul class="list-disc list-inside mb-4">
              <li class="mb-2"><strong>Segurança e Conformidade:</strong> Ferramentas para garantir 100% de conformidade com a LGPD.</li>
              <li class="mb-2"><strong>Confiança e Transparência:</strong> Aumente a confiança dos seus clientes e parceiros.</li>
              <li class="mb-2"><strong>Eficiência Operacional:</strong> Automatize processos e reduza custos.</li>
              <li class="mb-2"><strong>Proteção Contra Multas:</strong> Esteja sempre preparado para auditorias.</li>
              <li class="mb-2"><strong>Suporte Especializado:</strong> Equipe pronta para ajudar com a LGPD.</li>
          </ul>
          <p class="mb-4"><strong>Não perca essa oportunidade!</strong></p>
          <p class="mb-4">Invista na segurança que só uma gestão completa da LGPD pode oferecer. Entre em contato conosco hoje mesmo!</p>
          <p>Atenciosamente,</p>
          <p>Plataforma FácilLGPD</p>
          
          <a href="https://wa.me/5541992821401?text=Quero%20mais%20informa%C3%A7%C3%B5es!" 
            class="inline-flex justify-center py-2 px-8 mt-5 border border-transparent shadow-sm text-base font-medium rounded-sm text-white bg-green-500 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400">
            Quero saber mais
          </a>

          <button class="py-2 px-8 mt-5 border bg-red-500 float-right text-white" @click="$modal.hide('gostariaDeAssinar');">Fechar</button>
        </div>
    </modal>

  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Header from "@/components/Header";
import moment from 'moment';

export default {
  components: {
    Menu,
    Header,
  },
  data() {
    return {
      menuOpen: true,
      notificacao: null,
      form: {
        pergunta: 'Como você soube da Fácil ?',
        resposta: ''
      }
    };
  },
  methods: {
    async save() {  
      if(!this.form.resposta) return this.$vToastify.error("Escolha uma opção");
      const req = await this.$http.post(`/v1/pesquisa`, this.form);  
      if (req.data.success) {
          this.$vToastify.success("Salvo com sucesso!");
          this.$modal.hide('pesquisaComoSoube');
      } else {
          this.$vToastify.error(req.data.err);
      }
    },

    async okNotificacao() {
      await this.$http.put(`/v1/notificacoes`, { _id: this.notificacao._id, lida: true });

      const reqnotificacoes = await this.$http.post(`/v1/notificacoes/countNotificacoes`);
      await this.$store.commit('setNotificacoes', reqnotificacoes.data);

      this.$modal.hide('popUp');
    },

    async start() {
      if(this.$store.state.user && this.$store.state.user._id) {

        if(this.$route.query.token) return;

        if(this.$store.state.user.consultor) {
          const userReq = await this.$http.post('/v1/users/me');
        
          if(!userReq.data) {
            localStorage.removeItem('token');
            localStorage.removeItem('empresa');
            localStorage.removeItem('user');
            this.$store.commit('logout');
            this.$store.commit('setEmpresa', null);
            this.$router.push({path: '/login'});
          }

          this.$store.commit('setStripe', userReq.data.assinatura.stripe)

          if(userReq.data && !userReq.data.pesquisaComoSoube) {
            this.$modal.show('pesquisaComoSoube');
          }

          if(userReq.data && userReq.data.assinatura.pago && userReq.data.assinatura.ativo || userReq.data && userReq.data.fluxoAssinaturaManual) {
            this.$store.commit('setPago', true);
          }

          if(userReq.data.consultor && !userReq.data.fluxoAssinaturaManual && moment(userReq.data.data).add(15,'days').endOf('days').diff(moment().endOf('days'), 'days') >= 0) {
            this.$store.commit('setFreeTrial', true);
          }

          const notificacoesPopupReq = await this.$http.post('/v1/notificacoes/getNotificacoesPopUp');
          if(notificacoesPopupReq.data){
            this.notificacao = notificacoesPopupReq.data;
            this.$modal.show('popUp');
          }
        }

        if(this.$store.state.user.cliente) {
          const userReq = await this.$http.post('/v1/pessoas/me');

          if(!userReq.data.pessoa) {
            localStorage.removeItem('token');
            localStorage.removeItem('empresa');
            localStorage.removeItem('user');
            this.$store.commit('logout');
            this.$store.commit('setEmpresa', null);
            this.$router.push({path: '/login'});
          }
        }

        if(this.$store.state.user.profissional) {
          const userReq = await this.$http.post('/v1/profissionais/me');
        
          if(!userReq.data.pessoa) {
            localStorage.removeItem('token');
            localStorage.removeItem('empresa');
            localStorage.removeItem('user');
            this.$store.commit('logout');
            this.$store.commit('setEmpresa', null);
            this.$router.push({path: '/login'});
          }
        }

        const reqnotificacoes = await this.$http.post(`/v1/notificacoes/countNotificacoes`);
        await this.$store.commit('setNotificacoes', reqnotificacoes.data);
      }

      if(this.$store.state.empresa && this.$store.state.empresa._id) {
        this.$store.commit('setColor', { colorPrincipal: this.$store.state.empresa.colorPrincipal, colorSecundaria: this.$store.state.empresa.colorSecundaria });
      }
    },
  },
  beforeDestroy() {
    this.sockets.unsubscribe(this.$store.state.user._id.toString());
  },
  async mounted() {
    this.start();
  },
};
</script>
