import axios from 'axios';
import NProgress from 'nprogress';
import router from '@/router';
import store from "../store";

NProgress.configure({ showSpinner: false });

axios.defaults.baseURL = `${process.env.VUE_APP_API}/`;

// Intercept the request to make sure the token is injected into the header.
axios.interceptors.request.use(config => {
  if(store.state.empresa && config.headers['Content-Type'] !== 'multipart/form-data'){
    config.data = {...config.data, empresa: store.state.empresa}
  }
  config.headers.Authorization = `${localStorage.getItem('token')}`;
  NProgress.start();
  return config;
});

axios.interceptors.response.use(
  response => {
    if (response.statusCode === 403) {
      router.push('/login');
    }

    NProgress.done();
    return response;
  },
  error => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem('token');
      store.commit('logout');
      router.push('/login');
    }

    NProgress.done();
    return Promise.reject(error);
  }
);

const http = {
  postFile: (url, formData) => axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
  post: (url, data) => axios.post(url, data),
  put: (url, data) => axios.put(url, data),
  get: url => axios.get(url),
  delete: (url, data) => axios.delete(url, data),
  patch: (url, data) => axios.patch(url, data),
  getDropzoneConfig: (url, method, options ) =>
    Object.assign(options, {
      url: `${process.env.VUE_APP_API}/${url}`,
      headers: { Authorization: localStorage.getItem('token'), empresa: store.state.empresa && store.state.empresa._id ? store.state.empresa._id : null },
      method
  })
};

export default http;
