import Vue from 'vue'
import VueToastify from "vue-toastify";
import Vue2Editor from "vue2-editor";
import moment from 'vue-moment';
import App from './App.vue'
import './assets/styles/index.css'
import http from './services/http';
import Pagination from 'vue-pagination-2';
import VModal from 'vue-js-modal';
import Multiselect from 'vue-multiselect';
import VTooltip from 'v-tooltip';
import 'v-tooltip/dist/v-tooltip.css';
import VueSocketIO from 'vue-socket.io';
import VueApexCharts from 'vue-apexcharts';
import VueConfirmDialog from 'vue-confirm-dialog';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ProgressBar from 'vuejs-progress-bar';
import VueTheMask from 'vue-the-mask';
import VueCreditCardValidation from 'vue-credit-card-validation';
import VuePaycard from "vue-paycard";
import VueSocialSharing from 'vue-social-sharing';
import Navbar from '@/components/Navbar'

Vue.component('multiselect', Multiselect);
Vue.component('pagination', Pagination);
Vue.component('apexchart', VueApexCharts);
Vue.component('Navbar', Navbar);
Vue.use(VueSocialSharing);
Vue.use(VuePaycard);
Vue.use(VueCreditCardValidation);
Vue.use(VueTheMask);
Vue.use(ProgressBar);
Vue.use(VueLoading);
Vue.use(VueConfirmDialog);
Vue.use(VTooltip);
Vue.use(VModal, { componentName: 'modal', reset: true });
Vue.use(VueToastify);
Vue.use(Vue2Editor);
Vue.use(moment);
Vue.config.productionTip = false;
Vue.prototype.$http = http;

import router from './router'
import store from './store';

Vue.use(new VueSocketIO({
  debug: true,
  connection: process.env.VUE_APP_API.replace('/api', ''),
  vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
  },
  options: { path: "/socket.io/" } //Optional options
}))

Vue.mixin({
  data() {
    return {
      api: '',
      url: window.location.host,
      url_api : process.env.VUE_APP_API,
    }
  },
  methods: {
    isUserRoot() {
      return store.state.user && store.state.user.email === 'root@facillgpd.com.br'
    },
    isRoot() {
      return localStorage.getItem("root") === 'yes';
    },
    hasAccess(roles) {
      if (roles.indexOf(store.state.user.role) > -1) {
        return true;
      }

      return false;
    },
    formatPrice(value) {
      if (!value) return '0,00';
      return value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    },
    formatIntNumber(value) {
      if (!value) return '0';
      return parseInt(value).toLocaleString('pt-BR');
    },
    padZero: function (str, len) {
      len = len || 2;
      var zeros = new Array(len).join('0');
      return (zeros + str).slice(-len);
    },
    invertColor: function (hex, bw) {
      if (hex) {
        if (hex.indexOf('#') === 0) {
          hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        var r = parseInt(hex.slice(0, 2), 16),
            g = parseInt(hex.slice(2, 4), 16),
            b = parseInt(hex.slice(4, 6), 16);
        if (bw) {
            // http://stackoverflow.com/a/3943023/112731
            return (r * 0.299 + g * 0.587 + b * 0.114) > 186
                ? '#000000'
                : '#FFFFFF';
        }
        // invert color components
        r = (255 - r).toString(16);
        g = (255 - g).toString(16);
        b = (255 - b).toString(16);
        // pad each with zeros and return
        return "#" + this.padZero(r) + this.padZero(g) + this.padZero(b);
      } else {
        return "#fff";
      }
    }
  },
  beforeMount() {
    this.api = process.env.VUE_APP_API;
  },
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
